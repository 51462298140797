
/* Evergreen Financial Home - Scoped CSS */
.btn_brnd:focus{
  outline: none !important;
  box-shadow: none !important;
}
.tabs-section {
  text-align: center;
  padding: 2rem;
}

.tabs-container {
  display: flex;
  justify-content: center;
  /* background-color: #f8f9fa;  */
  padding: 0.5rem;
  border-radius: 0.5rem;
  /* max-width: 600px; */
  margin: 0 auto;
}

.btn_brnd,
.btn_brnd_inactive {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  display: inline-flex;
  text-decoration: none;
  padding: 10px 30px;
  cursor: pointer;
  margin: 0 5px;
  transition: all 0.3s ease;
  border: 1px solid #4e9c4b !important;
  border-radius: 10px !important;
}

/* Active tab style */
/* .btn_brnd {
  color: #ffffff;
  background: #4e9c4b;
} */

/* Inactive tab style */
.btn_brnd_inactive {
  color: #4e9c4b;
  background: white;
}

/* Hover states */
.btn_brnd:hover {
  color: #4e9c4b;
  background: white;
}

.btn_brnd_inactive:hover {
  color: #ffffff;
  background: #4e9c4b;
}

.tab-content {
  margin-top: 1.5rem;
  padding: 1rem;
  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin: 1rem auto 0;
  color: #495057;
}



.eg-page {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  color: #334155;
  min-height: 100vh;
  background: linear-gradient(to bottom, #f8fafc, #f1f5f9);
}

/* Header */
.eg-header {
  background-color: white;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  padding: 1.5rem 0;
}

.eg-brand {
  font-size: 1.5rem;
  font-weight: 700;
  color: #047857; /* emerald-700 */
  margin: 0;
}

/* Main Content */
.eg-section {
  margin-bottom: 3rem;
  padding: 0 1rem;
}

.eg-title {
  font-size: 1.875rem;
  font-weight: 700;
  margin-bottom: 2rem;
}

.eg-title-lg {
  font-size: 2.25rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
}

@media (min-width: 768px) {
  .eg-title-lg {
    font-size: 2.5rem;
  }
}

.eg-text-center {
  text-align: center;
}

.eg-content-max {
  max-width: 48rem;
  margin: 0 auto;
}

.eg-content-wide {
  max-width: 64rem;
  margin: 0 auto;
  display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
}

.eg-text-lg {
  font-size: 1.125rem;
  margin-bottom: 1rem;
}

.eg-text-xl {
  font-size: 1.25rem;
}

.eg-text-sm {
  font-size: 0.875rem;
}

.eg-font-medium {
  font-weight: 500;
}

.eg-text-italic {
  font-style: italic;
}

.eg-text-muted {
  color: #64748b; /* slate-500 */
}

.eg-button-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  margin-top: 2rem;
  align-items: center;
}

.eg-button {
  display: inline-block;
  font-weight: 500;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: all 0.15s ease-in-out;
  cursor: pointer;
  text-decoration: none;
}

.eg-button-primary {
  background-color: #10b981; /* emerald-600 */
  border: 1px solid #10b981;
  color: white;
}

.eg-button-primary:hover {
  background-color: #047857; /* emerald-700 */
  border-color: #047857;
  color: white;
}

.eg-button-outline {
  background-color: transparent;
  border: 1px solid #d1d5db; /* gray-300 */
  color: #374151; /* gray-700 */
}

.eg-button-outline:hover {
  background-color: #f3f4f6; /* gray-100 */
  color: #111827; /* gray-900 */
}

.eg-button-lg {
  padding: 0.75rem 1.5rem;
  font-size: 1.125rem;
}

.eg-button-group {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
  justify-content: center;
}

.eg-button-stack {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.eg-full-width {
  width: 100%;
}

/* Cards */
.eg-card {
  background-color: #fff;
    /* border: 1px solid rgba(0, 0, 0, .1);
    border-radius: .5rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, .1); */
    margin-bottom: 1.5rem;
    overflow: hidden;
}

.eg-card-header {
  padding: 1.25rem 1.25rem 0.5rem;
}

.eg-card-title {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.eg-card-description {
  color: #64748b; /* slate-500 */
  margin-bottom: 0.5rem;
}

.eg-card-content {
  padding: 1.25rem;
}

.eg-h-100 {
  height: 100%;
}

/* Info Box */
.eg-info-box {
  background-color: #f8fafc; /* slate-50 */
  border-radius: 0.375rem;
  padding: 1rem;
  margin-top: 1.5rem;
}

/* Video Placeholder */
.eg-video-placeholder {
  background-color: #e2e8f0; /* slate-200 */
  border-radius: 0.375rem;
  height: 12rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}

/* Grid */
.eg-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
}

@media (min-width: 768px) {
  .eg-grid {
    grid-template-columns: 1fr 1fr;
  }
}

.eg-grid-item-full {
  grid-column: 1 / -1;
}

/* Lists */
.eg-list {
  list-style-type: disc;
  padding-left: 1.25rem;
}

.eg-list li {
  margin-bottom: 0.25rem;
}

/* Questionnaire */
.eg-questionnaire {
  margin-bottom: 1rem;
  text-align: center;
}

.eg-question {
  font-size: 1.125rem;
  font-weight: 500;
  margin-bottom: 1rem;
}

.eg-result {
  /* background-color: #f8fafc;  */
  border-radius: 0.375rem;
  padding: 1rem;
  margin-top: 1rem;
}

.eg-result-title {
  font-size: 1.125rem;
  font-weight: 500;
  margin-bottom: 1rem;
}

/* Footer */
.eg-footer {
  background-color: #1e293b; /* slate-800 */
  color: white;
  padding: 2rem 0;
}

.eg-footer-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 2rem;
}

@media (min-width: 768px) {
  .eg-footer-content {
    flex-direction: row;
    align-items: flex-start;
  }
}

.eg-footer-brand {
  margin-bottom: 1rem;
}

@media (min-width: 768px) {
  .eg-footer-brand {
    margin-bottom: 0;
  }
}

.eg-footer-title {
  font-size: 1.25rem;
  font-weight: 700;
  color: #34d399; /* emerald-400 */
  margin: 0;
}

.eg-footer-links {
  display: flex;
  gap: 2rem;
}

.eg-footer-heading {
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.eg-footer-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.eg-footer-list li {
  margin-bottom: 0.25rem;
}

.eg-footer-link {
  color: white;
  text-decoration: none;
}

.eg-footer-link:hover {
  color: #34d399; /* emerald-400 */
}

.eg-footer-copyright {
  text-align: center;
  font-size: 0.875rem;
  color: #94a3b8; /* slate-400 */
  margin-top: 2rem;
}

/* Spacing Utilities */
.eg-mb {
  margin-bottom: 1rem;
}

.eg-mb-sm {
  margin-bottom: 0.5rem;
}

.eg-mt {
  margin-top: 1.5rem;
}

.eg-mt-sm {
  margin-top: 0.5rem;
}

.eg-pt {
  padding-top: 1.5rem;
}

/* Icon */
.eg-icon-sm {
  display: inline-block;
  margin-left: 0.5rem;
  vertical-align: middle;
}

